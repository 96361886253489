.admin-login{
    height: 100vh;
    align-items: center;
background-position:center;
 position: relative;
overflow: hidden;
background-repeat: no-repeat;
background-size: cover; 
}
.admin-login .container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30vw;
    transform: translate(-50%, -50%);
    background: rgba(184, 184, 184, 0.48);
    backdrop-filter: blur(16px);
    border-radius: 24px;
    color: var(--white);
  }

  .login-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .login-card input{
    border-radius: 16px;
    border: none;
  }
  .login-card input:focus  {
    border: none;
    outline: none;
  }
  .login-card input:active  {
    border: none;
  }
.login-button{
    border: none;
    background: #119F93;
border-radius: 16px;
color: var(--white);
}
.login-button .checkbox{
    border-radius: 8px;
}
.login-card p{
color:var( --errorred2)}
  @media screen and (max-width:1200px){
    .admin-login .container {
        width: 50vw;}
  }
  @media screen and (max-width:767px){
    .admin-login .container {
        width: 90vw;
        border-radius: 18px;
    }
    .admin-login img{
        transform: scale(0.7);
    }
  }
