@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); 
*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Poppins';
}

body {
  font-family: 'Poppins'
}
/*Following Tailwind css breakpoints*/
.box-shadow{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}
.box-shadow-main-banner{
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}
.black-shade{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1%, #000000 100%);}
.min-65{
  min-height: 65vh;
}
.min-50{
  min-height: 50vh;
}
h1{
  font-size: 2.25rem;
  line-height: 3.37rem;
  word-spacing: 0;
  font-weight: 600;
}
h2{
  font-size: 1.81rem;
  line-height: 2.75rem;
  word-spacing: 0;
  font-weight: 600;
}
h3{
  font-size: 1.43rem;
  line-height: 2.18rem;
  word-spacing: 0;
  font-weight: 600;
}
h4{
  font-size: 1.25rem;
  line-height: 1.87rem;
  word-spacing: 0;
  font-weight: 600;
}
h5{
  font-size: 1.18rem;
  line-height: 1.75rem;
  word-spacing: 0;
  font-weight: 600;
}
h6{
  font-size: 1.12rem;
  line-height: 1.68rem;
  word-spacing: 0;
  font-weight: 600;
}
p{
  font-size: 1.12rem;
  line-height: 1.68rem;
  word-spacing: 0;
  font-weight: 400;
 
}
small{
  font-size: 0.87rem;
  line-height: 1.31rem;
  word-spacing: 0;
  font-weight: 400;
}
i{
    font-size: 2.25rem;
}
@media screen and (min-width:640px) and (max-width:992px) {
  h1{
    font-size: 1.81rem;
    line-height: 2.75rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h2{
    font-size: 1.43rem;
  line-height: 2.18rem;
  word-spacing: 0;
  font-weight: 600;
  }
  h3{
    font-size: 1.12rem;
    line-height: 1.68rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h4{
    font-size: 1.25rem;
    line-height: 1.87rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h5{
    font-size: 1.18rem;
    line-height: 1.75rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h6{
    font-size: 1.12rem;
    line-height: 1.68rem;
    word-spacing: 0;
    font-weight: 600;
  }
  p{
    font-size: 1.12rem;
    line-height: 1.68rem;
    word-spacing: 0.5;
    font-weight: 400;
  }
  small{
    font-size: 0.87rem;
    line-height: 1.31rem;
    word-spacing: 0;
    font-weight: 400;
  }
 i{
    font-size: 1.81rem;
  }
}
@media screen and (max-width:640px) {
  h1{
    font-size: 1.75rem;
    line-height: 2.62rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h2{
    font-size: 1.37rem;
  line-height: 2.75rem;
  word-spacing: 0;
  font-weight: 600;
  }
  h3{
    font-size: 1.12rem;
    line-height: 1.68rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h4{
    font-size: 1.18rem;
    line-height: 1.75rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h5{
    font-size: 1.12rem;
    line-height: 1.68rem;
    word-spacing: 0;
    font-weight: 600;
  }
  h6{
    font-size: 1rem;
    line-height: 1.5rem;
    word-spacing: 0;
    font-weight: 600;
  }
  p{
    font-size: 1rem;
    line-height: 1.5rem;
    word-spacing: 0.5;
    font-weight: 400;
  }
  small{
    font-size: 0.75rem;
    line-height: 1.125rem;
    word-spacing: 0;
    font-weight: 400;
  }
i{
    font-size: 1.75rem;
  }
}
.h-custom{
  min-height: 60vh;
  height: auto;
}

.anim-height{
  height: 80vh;
}

@media screen and (min-width:640px) and (max-width:992px) {

  .h-custom{
    min-height: 60vh;
    height: auto;
  }
}

 